import React from 'react'
import './Cart.css'
const Cart = () => {
  return (
    <div>
      
    </div>
  )
}

export default Cart
