import React from 'react'
import './Breadcrumbs.css'
import arrow_icon from '../Assets/breadcrum_arrow.png'

const Breadcrumbs = (props) => {
  const {product} = props;
  return (
    <div className='breadcrums'>
      Home <img src={arrow_icon} alt="arrow_icon"/> Shop <img src={arrow_icon} alt="arrow_icon"/>  {product.category} <img src={arrow_icon} alt="arrow_icon"/> {product.name}
    </div>
  )
}

export default Breadcrumbs
