import React, { createContext } from "react";  //step 1

import all_product from '../Components/Assets/all_product'  // step4

export const ShopContext = createContext(null) // step 2

//step3
const ShopContextProvider = (props) =>{
  const contextValue = { all_product }

 //step5
 return (
    <ShopContext.Provider value={contextValue}>
        {props.children}
    </ShopContext.Provider>
 )

}

export default ShopContextProvider;