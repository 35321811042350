import React, { useContext } from 'react'
import './css/ShopCategory.css'
import { ShopContext } from '../Context/ShopContext';
import download_icon from '../Components/Assets/dropdown_icon.png'
import Item from '../Components/Item/Item';

const ShopCategory = (props) => {
const {all_product} = useContext(ShopContext);


  return (
    <div className='shop-category'>
      <div className="shopcategory-banner">
      <img src={props.banner} alt="banner" />
      </div>  

      <div className="shopcategory-indexSort">
          <p><span>Showing 1 -12</span> out of 36 products</p>
          <div className="shopcategory-sort">
            Sort by <img src={download_icon} alt="" />
          </div>
      </div>

    <div className="shopcategory-products">
        {all_product.map((item, index)=>{
            if(props.category === item.category){

                return <Item key={index} id={item.id} name={item.name} image={item.image} new_price={item.new_price} old_price={item.old_price}  />
            }else{
               return null
            }

        })}
    </div>
        <div className="shopcategory-loadmore">
        Explore More

        </div>


    </div>
  )
}

export default ShopCategory
